import "./styles.css";
import React from "react";
import { PieChart,Cell, Pie } from "recharts";
const data01 = [
    { name: "Passionate believers in progress and people", value: 10 }
];
const data02 = [
    { name: "Respectful Challenger", value: 10, idContainer:'pie1_reveal_content' },
    { name: "Caring contributor", value: 10,  idContainer:'pie3_reveal_content' },
    { name: "Ambitious improver", value: 10,  idContainer:'pie2_reveal_content' }
];
const labelWidth = 100;



const RADIAN = Math.PI / 180;
const CX = 200;
interface Ip {
    cx:number, cy:number, midAngle:number, innerRadius:number, outerRadius:number, percent:number, index:number, text?:string
}

const renderCustomizedLabel2 = ({ cx, cy, midAngle, innerRadius, outerRadius }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
            Passionate believers
        </text>
    );
};


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const COLORS2 = ['#00a0b6', '#fcbd00', '#7c2e85', '#FF8042'];
const renderCustomizedLabel = ({
                                   cx,
                                   cy,
                                   midAngle,
                                   innerRadius,
                                   outerRadius,
                                   percent,
                                   index
                               }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const maxWidth = 200;
    const maxHeight = 60;
    const textContent = `Passionate believers in progress and people`;


    const processText = (inputText: string, cx: number, labelWidth: number) => {
        const words = inputText.split(/\s+/);

        const processedText: JSX.Element[] = [];
        let currentLine: string[] = [];

        words.forEach((word, index) => {
            const lineText = currentLine.join(' ');

            if ((lineText + ' ' + word).trim().length < 15) {
                currentLine.push(word);
            } else {
                processedText.push(
                    <tspan
                        key={index}
                        x={cx}
                        dy="1.1em"
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: labelWidth
                        }}
                    >
                        {lineText}
                    </tspan>
                );
                currentLine = [word];
            }
        });

        if (currentLine.length > 0) {
            processedText.push(
                <tspan
                    key={words.length}
                    x={cx}
                    dy="1.1em"
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: labelWidth
                    }}
                >
                    {currentLine.join(' ')}
                </tspan>
            );
        }

        return processedText;
    };


    return (
        <text
            x={cx}
            y={cy-60}
            fill="white"
            textAnchor="middle"
            dominantBaseline="central"
            style={{  whiteSpace: 'nowrap' }}
        >
            {processText(textContent, cx,150)}

        </text>
    );
};

const textOnHalfCircle = (inputText: string, cx: number, cy: number, radius: number) => {
    const words = inputText.split(/\s+/);

    const angleIncrement = Math.PI / words.length;
    let currentAngle = -Math.PI / 2; // Rozpoczynamy od góry okręgu

    const textElements = words.map((word, index) => {
        const x = cx + radius * Math.cos(currentAngle);
        const y = cy + radius * Math.sin(currentAngle);

        currentAngle += angleIncrement;

        return (
            <tspan
                key={index}
                x={x}
                y={y}
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {word}
            </tspan>
        );
    });

    return textElements;
};
const handleClick = (data:any[], index:number,id: string) => {
    let allPies = document.querySelectorAll('.pie_content');
    allPies.forEach((pie) =>{
        let pieElement = pie as HTMLElement;

        pieElement.classList.remove('show_pie');
    });
    let centerElem = document.getElementById("circle_reveal");
    centerElem?.classList.remove('show_pie');
    const elementToScroll = document.getElementById(id);

    if (elementToScroll) {
        elementToScroll.classList.add('show_pie');
        elementToScroll.scrollIntoView({ behavior: "smooth" });
    }
}

export default function App():any {
    return (
        <div>
            <PieChart width={600} height={600}>
                <Pie
                    data={data01}
                    dataKey="value"
                    cx={300}
                    cy={300}
                    label={renderCustomizedLabel}
                    outerRadius={100}
                    fill="#8884d8"
                    stroke="#8884d8"
                    onClick={() => {
                        const elementToScroll = document.getElementById("tab_holder");
                        let allPies = document.querySelectorAll('.pie_content');
                        allPies.forEach((pie) =>{
                            let pieElement = pie as HTMLElement;

                            pieElement.classList.remove('show_pie');
                        });

                        if (elementToScroll) {
                            let centerElem = document.getElementById("circle_reveal");
                            centerElem?.classList.add('show_pie');
                            elementToScroll.scrollIntoView({ behavior: "smooth" });
                        }
                    }}
                >
                    {/*{data01.map((entry, index) => (*/}
                    {/*    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />*/}
                    {/*))}*/}
                </Pie>
                <Pie
                    data={data02}
                    dataKey="value"
                    cx={300}
                    cy={300}
                    innerRadius={110}
                    outerRadius={190}
                    fill="#82ca9d"
                    label={renderCustomizedLabel2}
                    onClick={(_, index) => handleClick(data02, index, data02[index].idContainer)}
                >
                    {data02.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
                    ))}
                </Pie>

                {/*<Pie*/}
                {/*    data={data02}*/}
                {/*    dataKey="value"*/}
                {/*    cx={200}*/}
                {/*    cy={200}*/}
                {/*    innerRadius={130}*/}
                {/*    outerRadius={160}*/}
                {/*    fill="#82ca9d"*/}
                {/*    //label*/}
                {/*/>*/}
            </PieChart>
        </div>
    );
}
